import type { AssetItem } from '../../types';
import { useUserAccountType } from '@/src/features/user';

export const useGetCurrentUserAssetPercent = () => {
	const userAccountType = useUserAccountType();

	return (asset: AssetItem) => {
		return asset.percents[userAccountType.value];
	};
};
