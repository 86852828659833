<script setup lang="ts">
import { useGetCurrentUserAssetPercent } from '../core/application';
import type { AssetItem } from '../types';

defineProps<{
	asset: AssetItem;
	withPercentage?: boolean;
}>();

const getCurrentUserAssetPercent = useGetCurrentUserAssetPercent();
</script>

<template>
	<div class="flex gap-2 items-center">
		<img
			:src="asset.image"
			:alt="asset.name"
			class="roundend-lg h-5"
		>

		<span class="text-xs font-medium text-white">
			{{ asset.name }}
		</span>

		<span
			v-if="withPercentage"
			class="text-green-400"
		>
			{{ `${getCurrentUserAssetPercent(asset)} %` }}
		</span>
	</div>
</template>
